@import "./variables.scss";

// Landing
.particles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.dots {
  position: absolute;
  width: 14rem;
  height: 14rem;
  top: -4rem;
  left: -4rem;
  opacity: 50%;
  z-index: -1;
}
// About
.about li {
  font-size: 1.5rem;
  font-weight: 500;
  &::before {
    content: "🛸 ";
  }
}
// Work
.work li {
  font-size: 1.4rem;
  font-weight: 300;
  &::before {
    content: "🪐 ";
  }
}
