@import "../../variables.scss";

.hamburger {
  width: 3rem;
  height: 2.5rem;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 0.4rem;
    width: 100%;
    background: $mint;
    border-radius: 1rem;
    left: 0;
    transition: 0.25s ease-in-out;
  }
  &:hover {
    transition: all 0.3s ease-out;
    span {
      background: $purple;
    }
  }
}

.hamburger span:nth-child(1) {
  top: 0;
}
.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  top: 1rem;
}
.hamburger span:nth-child(4) {
  top: 2rem;
}

.hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
}
.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}
.hamburger.open span:nth-child(1),
.hamburger.open span:nth-child(4) {
  top: 1rem;
  width: 0;
  left: 50%;
}
